<template>
  <Company />
</template>
<script>
  import Company from "@/components/Company/Company.vue";
  export default {
    components: {
      Company,
    },
  };
</script>
